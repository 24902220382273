<template>
  <EmDialog
    ref="dialog"
    title="Switch account"
    max-width="600"
    :show-confirm="false"
    @confirm="switchTenant"
  >
    <template #activator="{props: activatorProps}">
      <slot v-if="availableTenants.length > 0" name="activator" v-bind="activatorProps">
        <EmIconButton :color="color" title="Switch account" icon="mdi-account-switch-outline" v-bind="activatorProps" />
      </slot>
    </template>
    <template #default>
      <div class="em-scrollable-container">
        <div class="flex-grow-0">
          <div class="mb-2">
            <EmBody2 v-if="user.currentTenantInfo">
              You are currently on <strong>
                {{ user.currentTenantInfo.name }}
              </strong>
            </EmBody2>
          </div>
          <div>
            <EmBody2>
              Select the account you want to switch to
            </EmBody2>
          </div>
        </div>
        <div :class="{'max-height-40vh': display.mdAndUp, 'flex-grow-1': display.smAndDown }">
          <v-btn-toggle
            tile
            color="primary"
            class="d-flex flex-column height-100 mt-4"
            group
          >
            <EmSecondaryButton
              v-for="tenant in availableTenants"
              :key="tenant.id"
              :value="tenant"
              class="pa-4 mb-4"
              color="primary"
              @click="switchTenant(tenant)"
            >
              {{ tenant.name }}
            </EmSecondaryButton>
          </v-btn-toggle>
        </div>
      </div>
    </template>
  </EmDialog>
</template>
<script setup>
import { useDisplay } from 'vuetify'
const display = ref(useDisplay())
const props = defineProps({
  color: {
    type: String,
    default: 'white'
  },
  disableRedirect: {
    type: Boolean,
    default: false
  }
})
const user = useUserStore()
const emits = defineEmits(['switchTenant'])
const availableTenants = computed(() => user.readable?.tenants
  ?.filter(tenant => tenant.id !== user.currentTenantInfo?.id) || [])
const router = useRouter()
const dialog = ref()
const switchTenant = (selectedTenant) => {
  if (selectedTenant?.id) {
    dialog.value.close()
    emits('switchTenant')
    user.setCurrentTenantInformation(selectedTenant.id)
    if (!props.disableRedirect) {
      router.push('/')
    }
  }
}
</script>
